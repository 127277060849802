<template>
<div>

   <div class="d-flex position-relative">
          <img :src="imglocal+'/imagenes/ico/encuestas.svg'" class="me-3"  style="width: 40px; height: 40px;" alt="">
          <div class="media-body">
            <span class="h6 titulosg ">Encuestas Diligenciadas </span> <br>
            <span class="text-secondary"> Cuestionarios, Encuestas Diligenciadas por la persona </span>
          </div>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">

                        <div class="list-group" v-for="item in encuestas" :key="item.idencuesta" >
                            <a href="javascript:;" class="list-group-item list-group-item-action" @click="verencuesta(item.idencuesta)">
                                <strong>{{item.tituloencuesta}}</strong> <br>
                                {{item.descripcionencuesta}}
                            </a>
                        </div>
        </div>
    </div>

</div>
</template>

<script>

    import { ref, onMounted } from 'vue'
    import axios from 'axios'
    import moment from 'moment'

    import { useRouter, useRoute } from 'vue-router'


    export default {
       props: ['id'],
       setup(props) {


        const router = useRouter()
        const route = useRoute()

           const encuestas = ref([])

           const listarencuestas = () => {

               axios.get('/api/encuestapersona/'+props.id).then( response => {
                   encuestas.value = response.data
               }).catch( error => {
                        console.error(error);
                })

           }


           const verencuesta = (idencuesta) => {
                router.push({ name: 'respuestaspersona', params: { idpersona: props.id, idencuesta: idencuesta } })
            }

            onMounted(() => {

                listarencuestas()
            })

           return { encuestas, verencuesta }
       }
    }
</script>
 
<style>

</style>
